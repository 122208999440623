import { ASTWithName } from '@angular/compiler';

export type OauthProvider = 'hotmail' | 'google';
export type GitProvider = 'gitlab' | 'github';

export const constantes = {
    OAUTH_PROVIDERS: ['hotmail', 'google'],
    GIT_PROVIDERS: ['gitlab', 'github'],
    SHAPES: ['cercle', 'triangle', 'polygone', 'rectangle', 'trapeze'],
};
